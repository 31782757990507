<template>
	<div class="grid lg:grid-cols-3 gap-5">
		<div class="lg:col-span-2 h-full overflow-y-auto">
			<n-data-table title="會員列表" :columns="fields" :data="customers" actions="view,edit,search,refresh" @view="getCustomer" @edit="archiveCustomer">
				<template v-slot:firstName="{ row }"> {{ row.firstName + ' ' + row.lastName }}</template>
				<template v-slot:merchantId="{ row }"> {{ merchantName(row.merchantId).name['tc'] }}</template>
				<template v-slot:status="{ row }">
					<n-badge :color="statusName(row.status).color"> {{ statusName(row.status).label }}</n-badge>
				</template>
			</n-data-table>
		</div>
		<div class="lg:col-span-1 space-y-2 text-xl h-full overflow-auto">
			<p class="text-4xl pb-2">{{ customer.id ? '修改' : '新增' }}會員</p>
			<div class="flex space-x-2">
				<label for="merchant">所屬商戶</label>
				<n-select v-model="customer.merchantId" :options="merchants" :reduce="merchant => merchant.id" :get-option-label="merchant => merchant.name['tc']" class="w-full h-full text-base" placeholder="請選擇商戶"></n-select>
			</div>
			<template v-if="customer.merchantId">
				<div class="flex space-x-2">
					<label for="name">會員名字</label>
					<n-input id="name" type="text" placeholder="請輸入名字" v-model="customer.firstName" />
				</div>

				<div class="flex space-x-2">
					<label for="name">會員姓別</label>
					<n-input id="name" type="text" placeholder="請輸入姓別" v-model="customer.lastName" />
				</div>

				<div class="flex space-x-2">
					<label for="status">會員狀態</label>
					<n-select v-model="customer.status" :options="statusOptions" :reduce="status => status.value" class="w-full h-full text-base" placeholder="請選擇狀態"></n-select>
				</div>
			</template>
			<p v-if="error" class="text-danger">{{ '錯誤訊息:' + error }}</p>

			<div class="flex space-x-2">
				<n-button v-if="customer.id" @onClick="$router.go(0)" color="danger">返回</n-button>
				<n-button @onClick="customer.id ? updateCustomer(customer) : createCustomer(customer)" color="primary">{{ customer.id ? '修改會員' : '加入會員' }} </n-button>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { customer } from '@/model/customer'
export default {
	data() {
		return {
			customer: customer(),
			fields: [
				{
					label: '會員名稱',
					prop: 'firstName',
					sortable: true,
				},
				{
					label: '所屬商戶',
					prop: 'merchantId',
					sortable: true,
				},
				{
					label: '會員狀態',
					prop: 'status',
					sortable: true,
				},
			],
			error: '',
		}
	},

	computed: {
		...mapState(['statusOptions']),
		...mapGetters([
			// merchant
			'merchants',
			'merchantName',
			// customer
			'customers',
			'currentCustomer',
			// state
			'statusName',
		]),
	},
	methods: {
		async createCustomer(data) {
			try {
				await this.$store.dispatch('createCustomer', { customer: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async getCustomer(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('getCustomer', { id })
				this.customer = this.currentCustomer
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async archiveCustomer(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('archiveCustomer', { id })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async updateCustomer(data) {
			try {
				this.error = ''
				await this.$store.dispatch('updateCustomer', { id: data.id, customer: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
	},
}
</script>
